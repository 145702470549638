@import "../../Constantes";

//header -------------------------
.headerDashboard {
  background-color: $main-color-content;
}

.cursorItem{
  cursor: pointer;
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: auto;
  text-align: center;

  letter-spacing: 0.14em;
  word-spacing: 0;
  line-height: 2em;
  font-weight: normal;
}

.menuTitre {
  //font-family: 'Carme', sans-serif, 'google';
  font-size: 1.25rem;
  letter-spacing: 0.07em;
  word-spacing: 0;
  line-height: 2em;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
}

.menuToggle {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 9999;
}

.menuToggle .iconBar {
  display: block;
  width: 22px;
  height: 3px;
  background-color: $main-color-dark;
  margin-bottom: 5px;
}

/* Styles pour les écrans de petite taille */
@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .headerDashboard {
    height: $height-header-small;
  }
  .divToggle {
    display: block; /* Afficher le bouton du menu hamburger */
  }
  .menuBar {
    display: none;
  }
  /* Styles pour afficher le menu lorsqu'il est ouvert */
  .menuBar.show {
    position: absolute;
    top: $height-header-small;
    right: 0;
    left: 0;
    display: flex;
    height: calc(100vh - $height-header-small);
    flex-direction: column;
    background-color: $main-color-content;
    z-index: 1000;
  }

  .menuItem {
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .menuTitre {
    text-align: center;
    display: flex;
    flex-direction: column;
    place-content: center;
    height: $height-header-small;
    justify-content: center;
  }
}

/* Styles pour les écrans de taille moyenne et grande */
@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .headerDashboard {
    height: $height-header;
  }

  .divToggle {
    display: none; /* Masquer le bouton du menu hamburger */
  }

  .menuBar {
    display: flex;
    flex-direction: row;
    float: left;
    width: 100%;
    height: $height-header;

    justify-content: space-evenly;
    align-content: center;
    align-items: center;
  }
}
