@import "../../Constantes";

@media screen and (max-width: $SIZE_SMALL_SCREEN) {
    .contentPhoto {
        margin-right: 10px;
        margin-left: 10px;
    }
}
@media screen and (min-width: $SIZE_SMALL_SCREEN) {
    .contentPhoto {
        margin-right: 250px;
        margin-left: 250px;
    }
}