// COLOR PALETTE
$EMERALD:#2ECC71;
$NEPHITIS: #27AE60;
$TURQUOISE:#1ABC9C;
$GREEN_SEA: #16A085;
$PETER_RIVER:#3498DB;
$BELIZE_HOLE:#2980B9;
$WET_ASP:#34495E;
$MIDNIGHT:#2C3E50;
$AMETHYST: #9B59B6;
$WISTERIA:#8E44AD;
$SUN_FLOWER: #F1C40F;
$CARROT: #E67E22;
$ORANGE:#F39C12;
$PUMPKIN:#D35400;
$ALIZARIN: #E74C3C;
$POMEGRANATE:#C0392B;
$GREY:#111111;
$LIGHT_GREY:#7E7E7E;
$ASBE:#7F8C8D;
$CONC:#95A5A6;
$SILVER: #BDC3C7;
$CLOUD: #ECF0F1;
$WHITE:#FFFFFF;

// Attention Ancien IHM
$main-color-grey: #9A9A9A;
$main-color-font-grey: #2f3640;
$main-background-Color: #FFFFFF;
$main-background-color-content: #525659;
// FIN - Attention Ancien IHM

// New refont
$main-color-back: $SUN_FLOWER;
$main-color-dark: #333;
$main-color-content:white;
$height-header:120px;
$height-header-small:50px;

// Responsive
$SIZE_SMALL_SCREEN:1024px;