@import "../../Constantes";

$height-footer:40px;

// footer
.footerDashboard {
  height: $height-footer;
  display: flex;
  justify-content: center;
  letter-spacing: 0.14em;
  text-align: center;
  margin-top: 15px;
}

// Petite taille
@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .footerDashboard {
    flex-direction: row;
    font-size: 0.8rem;
  }
}

// Grande taille
@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .footerDashboard {
    flex-direction: row;
    font-size: 0.75rem;
  }
}