@import "./Constantes";

@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  html {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  html {
    font-size: 1.2rem;
  }
}

body {
  margin: 0;
  font-family: Montserrat-Regular, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  letter-spacing: 0.09em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  margin-top: 25px;
  text-align: center;
  background-color: #F2E0EE;
  color:$main-color-dark;
  padding: 10px 25px;
  border-radius: 5px;
  border-style: none;
  cursor:pointer;
  font-size: 11px;
  text-transform: uppercase;
}

button:hover{
  filter: brightness(85%);
}
