@import "../../Constantes.scss";

.dashboard{
  color:$main-color-dark;
  min-height: 100vh;
}

.absolute {
  position:absolute;
  width: 100%;
}

.relative {
  position: relative;
}

//Content --------------------
.contentDashboard{
  position: relative;
  background-color: $main-color-content;
}

.layoutContent {
  width: auto;
  background-color: $main-color-content;
  border-radius: 0;

  top:0;
  bottom:0;
  right: 0;
  left: 0;

  overflow: auto;
  overflow-x: hidden;
}

@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .contentDashboard{
    min-height: calc(100vh - $height-header-small);
  }
}

@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .contentDashboard{
    min-height: calc(100vh - $height-header);
  }
}
// end content ----------------

