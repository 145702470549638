@import "../../Constantes";

$COLOR_FC_SPINNER: $main-color-grey;

$SIZE_SMALL_SPINNER: 15px;
$SIZE_MIDDLE_SPINNER: 30px;
$SIZE_LARGE_SPINNER: 45px;

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerColor {
  border: calc($SIZE_SMALL_SPINNER / 10) solid $COLOR_FC_SPINNER;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner-animation 1s ease-in-out infinite;
}

.spinnerColorDark {
  border: calc($SIZE_SMALL_SPINNER / 10) solid $main-color-dark;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner-animation 1s ease-in-out infinite;
}

.spinnerStyleSmaller{
  display: inline-block;
  width: $SIZE_SMALL_SPINNER;
  height: $SIZE_SMALL_SPINNER;
}

.spinnerStyleMiddle{
  display: inline-block;
  width: $SIZE_MIDDLE_SPINNER;
  height: $SIZE_MIDDLE_SPINNER;
}

.spinnerStyleLargest{
  display: inline-block;
  width: $SIZE_LARGE_SPINNER;
  height: $SIZE_LARGE_SPINNER;

}