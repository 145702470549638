@import "../../../Constantes";

// Petite taille
@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .divContentThx {
    font-size: 0.9rem;
    margin-right: 0;
  }

  $SIZE_HEIGHT_DESCRIPTION:45px;
  .imagePrest {
    max-height: $SIZE_HEIGHT_DESCRIPTION;
    width: $SIZE_HEIGHT_DESCRIPTION;
    margin-right: 20px;
  }

  .linkThx {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
  }
}

// Grande taille
@media screen and (min-width: $SIZE_SMALL_SCREEN) {

  .divContentThx {
    font-size: 0.9rem;
    margin-right: 50px;
  }

  $SIZE_HEIGHT_DESCRIPTION:125px;
  .imagePrest {
    max-height: $SIZE_HEIGHT_DESCRIPTION;
    width: $SIZE_HEIGHT_DESCRIPTION;
    margin-right: 40px;
  }

  .linkThx {}
}

.divContentThx {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  width: 100%;
  max-width: 550px;
}

.divDescription {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.imagePrest {
  height: auto;
}

.divCenterImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}





