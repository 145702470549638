@import "../../Constantes";

$height-footer:40px;

.contentRowAllThx{
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: calc(100vh - $height-footer - $height-header);
}

.contentColumnAllThx{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.divTitle {
  text-align: center;

  text-transform: uppercase;
  letter-spacing: 0.14em;
  word-spacing: 0;
  line-height: 2em;
  font-weight: normal;
}

.divDataPrestataire {
  display: flex;
  justify-content: safe center;
  flex-direction: row;
  flex-wrap: wrap;
}

// Petite taille
@media screen and (max-width: $SIZE_SMALL_SCREEN) {

  .contentRowAllThx {
    margin-right: 10px;
    margin-left: 10px;
  }

  .divTitle {
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

// Grande taille
@media screen and (min-width: $SIZE_SMALL_SCREEN) {

  .contentRowAllThx{
    margin-right: 250px;
    margin-left: 250px;
  }
  .divTitle {
    margin-bottom: 40px;
  }
}